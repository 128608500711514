@import "../node_modules/ol/ol.css";
@import "../node_modules/ol-ext/dist/ol-ext.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./assets/css/fontawesome.min.css";
@import "./assets/css/regular.min.css";


html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.map-target {
    height: 100%;
    width: 100%;
}

/* Attribution control */
.ol-attribution {
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark semi-transparent background */
    padding: 0.1rem;
    font-size: 0.75rem;
    /* Smaller font size */
    color: #fff;
    /* White text color */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
    /* Slight shadow */
    position: absolute;
    bottom: 0rem;
    top: auto;
    /* Positioned at the bottom */
    left: 0;
    right: auto;
    /* Positioned at the left */
    z-index: 1000;
    /* Ensures it appears above other elements */
    min-height: 1.5rem;
    /* Minimal height for the attribution box */
    display: flex;
    align-items: center;
}

.ol-attribution.ol-uncollapsible {
    right: auto;
    border-radius: 0 0.25rem 0 0;
    /* Rounded corners on the right side */
}

/* Rotate control */
.ol-rotate {
    position: absolute;
    top: auto;
    /* Override default top position */
    left: auto;
    /* Override default left position */
    bottom: 2.9rem;
    /* Spaced consistently above the zoom control */
    right: 0.5rem;
    z-index: 1100;
    /* Higher z-index to appear above attribution */
}

.ol-rotate .ol-compass {
    font-weight: bold;
    /* Increase the font weight to make it bolder */
    font-size: 1.5rem;
    /* Optionally increase the size for more prominence */
    color: #333;
    /* Adjust the color if needed for better visibility */
}

/* Hide the default arrow in the rotate control */
.ol-rotate-reset {
    background: none;
    /* Remove default background */
    border: none;
    /* Remove border if any */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ol-rotate button {
    height: 29px;
    width: 29px;
}

/*geolocation control button */

.ol-geolocation {
    position: absolute;
    top: auto;
    /* Override default top position */
    left: auto;
    /* Override default left position */
    bottom: 0.5rem;
    /* Spaced consistently above the attribution control */
    right: 0.5rem;
    z-index: 1100;
    /* Higher z-index to appear above attribution */
    display: flex;
    /* This makes the children (buttons) align horizontally */

}

.ol-geolocation button {
    height: 29px;
    width: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*legend control button*/
.ol-legend.ol-unselectable{
    position: absolute;
    top: auto;
    /* Override default top position */
    left: auto;
    /* Override default left position */
    bottom: 0.5rem;
    /* Spaced consistently above the attribution control */
    right: 2.94rem;
    z-index: 1100;
    /* Higher z-index to appear above attribution */
    display: flex;
    /* This makes the children (buttons) align horizontally */
}
.ol-legend > button {
    height: 29px;
    width: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ol-legend li:hover {
    background-color: rgba(0,60,136,.5);
  }
  .ol-control.ol-legend > button:first-child:after {
    content: none; /* Uklanja sadržaj */
    display: none; /* Skriva pseudo-element */
}
.ol-control.ol-legend > button:first-child:before {
    content: none; /* Uklanja sadržaj */
    display: none; /* Skriva pseudo-element */
}


/* Uklanjanje strelice samo za dropdown s ID-em #menu-auth */
#index-auth.dropdown-toggle::after {
    display: none;
}

/* Apply bottom positioning for mobile screens */
@media (max-width: 767.98px) {
    #index-offcanvas {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: auto;
        max-height: 50%;
        transition: transform 0.3s ease-in-out;
        transform: translateY(100%);
        resize: none;
        overflow: auto;
        max-width: 100%;
        min-width: 100%;
    }

    #index-offcanvas.show {
        transform: translateY(0);
    }
}

/* Apply start (left) positioning for larger screens */
@media (min-width: 768px) {
    #index-offcanvas {
        position: fixed;
        left: 0;
        top: 0;
        width: 400px;
        height: 100%;
        max-height: none;
        transition: transform 0.3s ease-in-out;
        transform: translateX(-100%);
        resize: horizontal;
        overflow: auto;
        max-width: 60%;
        min-width: 300px;
    }

    #index-offcanvas.show {
        transform: translateX(0);
    }

    #index-offcanvas.offcanvas-start {
        transition: none;
    }
}

/* tile switcher */
.tile-switcher .dropdown-menu {
    min-width: 200px;
}

.tile-switcher>button {
    width: 29px;
    height: 29px;
}

.tile-switcher .dropdown-item img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 2px solid transparent;
    border-radius: 4px;
}

.tile-switcher .dropdown-item img.selected {
    border-color: blue;
}

/* Hover effect for desktop */
@media (min-width: 768px) {
    .layer-switcher:hover .dropdown-menu {
        display: block;
    }
}

/* metersCmms upload visible only on admin account */
#upload {
    display: none;
}

